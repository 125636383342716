<template>
    <div class="flex column center" style="margin-bottom:4rem;">
        <div class="new_box">
            <div class="titlee">中华人民共和国民法典</div>
            <p class="time">发布时间:2020-06-10 文章来源:北大法宝</p>
            <div class="space_content center_">
              <p class="bold"><br/>中华人民共和国民法典</p><br/><br/><br/><br/><br/>中华人民共和国主席令<br/>（第四十五号）<br/>《中华人民共和国民法典》已由中华人民共和国第十三届全国人民代表大会第三次会议于2020年5月28日通过，现予公布，自2021年1月1日起施行。<br/><br/>中华人民共和国主席 习近平<br/>2020年5月28日<br/><br/><br/>中华人民共和国民法典<br/>（2020年5月28日第十三届全国人民代表大会第三次会议通过）<br/><br/><br/>目录

            </div>
            
            <p class="space_content" style="margin-left:4rem;text-indent:0rem;"><br/>第一编  总则
<br/>第一章  基本规定
<br/>第二章  自然人
<br/>第一节  民事权利能力和民事行为能力
<br/>第二节  监护
<br/>第三节  宣告失踪和宣告死亡
<br/>第四节  个体工商户和农村承包经营户
<br/>第三章  法人
<br/>第一节  一般规定
<br/>第二节  营利法人
<br/>第三节  非营利法人
<br/>第四节  特别法人
<br/>第四章  非法人组织
<br/>第五章  民事权利
<br/>第六章  民事法律行为
<br/>第一节  一般规定
<br/>第二节  意思表示
<br/>第三节  民事法律行为的效力
<br/>第四节  民事法律行为的附条件和附期限
<br/>第七章  代理
<br/>第一节  一般规定
<br/>第二节  委托代理
<br/>第三节  代理终止
<br/>第八章  民事责任
<br/>第九章  诉讼时效
<br/>第十章  期间计算
<br/>第二编  物权
<br/>第一分编  通则
<br/>第一章  一般规定
<br/>第二章  物权的设立、变更、转让和消灭
<br/>第一节  不动产登记
<br/>第二节  动产交付
<br/>第三节  其他规定
<br/>第三章  物权的保护
<br/>第二分编  所有权
<br/>第四章  一般规定
<br/>第五章  国家所有权和集体所有权、私人所有权
<br/>第六章  业主的建筑物区分所有权
<br/>第七章  相邻关系
<br/>第八章  共有
<br/>第九章  所有权取得的特别规定
<br/>第三分编  用益物权
<br/>第十章  一般规定
<br/>第十一章  土地承包经营权
<br/>第十二章  建设用地使用权
<br/>第十三章  宅基地使用权
<br/>第十四章  居住权
<br/>第十五章  地役权
<br/>第四分编  担保物权
<br/>第十六章  一般规定
<br/>第十七章  抵押权
<br/>第一节  一般抵押权
<br/>第二节  最高额抵押权
<br/>第十八章  质权
<br/>第一节  动产质权
<br/>第二节  权利质权
<br/>第十九章  留置权
<br/>第五分编  占有
<br/>第二十章  占有
<br/>第三编  合同
<br/>第一分编  通则
<br/>第一章  一般规定
<br/>第二章  合同的订立
<br/>第三章  合同的效力
<br/>第四章  合同的履行
<br/>第五章  合同的保全
<br/>第六章  合同的变更和转让
<br/>第七章  合同的权利义务终止
<br/>第八章  违约责任
<br/>第二分编  典型合同
<br/>第九章  买卖合同
<br/>第十章  供用电、水、气、热力合同
<br/>第十一章  赠与合同
<br/>第十二章  借款合同
<br/>第十三章  保证合同
<br/>第一节  一般规定
<br/>第二节  保证责任
<br/>第十四章  租赁合同
<br/>第十五章  融资租赁合同
<br/>第十六章  保理合同
<br/>第十七章  承揽合同
<br/>第十八章  建设工程合同
<br/>第十九章  运输合同
<br/>第一节  一般规定
<br/>第二节  客运合同
<br/>第三节  货运合同
<br/>第四节  多式联运合同
<br/>第二十章  技术合同
<br/>第一节  一般规定
<br/>第二节  技术开发合同
<br/>第三节  技术转让合同和技术许可合同
<br/>第四节  技术咨询合同和技术服务合同
<br/>第二十一章  保管合同
<br/>第二十二章  仓储合同
<br/>第二十三章  委托合同
<br/>第二十四章  物业服务合同
<br/>第二十五章  行纪合同
<br/>第二十六章  中介合同
<br/>第二十七章  合伙合同
<br/>第三分编  准合同
<br/>第二十八章  无因管理
<br/>第二十九章  不当得利
<br/>第四编  人格权
<br/>第一章  一般规定
<br/>第二章  生命权、身体权和健康权
<br/>第三章  姓名权和名称权
<br/>第四章  肖像权
<br/>第五章  名誉权和荣誉权
<br/>第六章  隐私权和个人信息保护
<br/>第五编  婚姻家庭
<br/>第一章  一般规定
<br/>第二章  结婚
<br/>第三章  家庭关系
<br/>第一节  夫妻关系
<br/>第二节  父母子女关系和其他近亲属关系
<br/>第四章  离婚
<br/>第五章  收养
<br/>第一节  收养关系的成立
<br/>第二节  收养的效力
<br/>第三节  收养关系的解除
<br/>第六编  继承
<br/>第一章  一般规定
<br/>第二章  法定继承
<br/>第三章  遗嘱继承和遗赠
<br/>第四章  遗产的处理
<br/>第七编  侵权责任
<br/>第一章  一般规定
<br/>第二章  损害赔偿
<br/>第三章  责任主体的特殊规定
<br/>第四章  产品责任
<br/>第五章  机动车交通事故责任
<br/>第六章  医疗损害责任
<br/>第七章  环境污染和生态破坏责任
<br/>第八章  高度危险责任
<br/>第九章  饲养动物损害责任
<br/>第十章  建筑物和物件损害责任
<br/>附则
          </p>
          <p class="space_content bold" style="margin-left:2rem;text-indent:0rem;">
            详情见原文链接:<a target="_blank" style="word-wrap:break-word;" href="https://pkulaw.com/chl/aa00daaeb5a4fe4ebdfb.html">
              https://pkulaw.com/chl/aa00daaeb5a4fe4ebdfb.html
            </a>
            </p>
            
        </div>
        <div class="flex wrap col442" style="width:100%;text-align:left;margin-top:1rem">
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex" style="margin-right:10%">
                    <p style="white-space:nowrap">上一篇:</p>
                    <router-link class="a" :to="'/news/law/2'"><p class="ellipsis1">司法鉴定程序通则</p></router-link>
                    
                </div>
            </div>
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex">
                    <p style="white-space:nowrap">下一篇:</p>
                    无
                    
                </div>
            </div>
            <div class="col2 flex  end1 center">
                <router-link class="a" style="color:#fff" :to="'/news/law'"><el-button type="primary">返回列表</el-button></router-link >
                
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return { 
            data:{

            }
        }
    },
}
</script>
<style scoped>
.space_content{
  margin-bottom:1rem;
  line-height:2rem;
}
</style>